<template>
    <div class="inner_pages">
        <div class="respective_content" id="page_index">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Quick Pages</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortPage = !sortPage" v-tooltip="`Sort Pages Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'pages')" @click="importPage = !importPage" v-tooltip="`Import a shared page`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li class="add_btn light pointer" @click="newPage = true"><i class="fas fa-plus-circle"></i>Create A Page</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li class="p-0" v-show="selectedPage.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedPage.length ? `${selectedPage.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedPage.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'pages')" @click="handleDuplicatePage(selectedPage)">Duplicate</li>
                                    <li v-if="companyUserCan('delete', 'pages')" class="danger" @click="handleDeletePage(selectedPage)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the pages feature</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="modeFilterDropdown = !modeFilterDropdown" v-click-outside="closeModeFilterDropdown">
                            {{ modeFilterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="modeFilterDropdown ? 'active' : ''">
                                <ul>
                                    <li v-for="(mode, m) in modes" :key="m" :class="{'active' : mode.title == modeFilterTitle}" @click="sortingModeFilter(mode)">
                                        {{ mode.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="page-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="listing_loader" v-show="pageLoader"><quote-loader /></div>
                <div class="action_content" v-show="!pageLoader">
                    <asset-folder v-model="assetFolder" title="Page" asset-type="pages" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :section-breadcrumb="folderBreadcrumb['pages']" />
                    <ul class="playbook_list" v-if="!isArchived || folderParams.parent_id > 0">
                        <li v-for="(page, p) in pages.data" :key="p">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedPage.includes(page.id) }">
                                    <img v-if="page.dashboard_thumb" :src="page.dashboard_thumb" class="img">
                                    <img v-else src="@/assets/images/thumb/default-page.svg" class="img">
                                    <span v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact" class="replace_img" @click="editThumbnail = true; activePage = page;">
                                        <img src="@/assets/images/image.svg">
                                    </span>
                                    <!-- <div class="switch_list">
                                        <label :for="`client_tease-${page.id}`" class="switch_option capsule_btn border-0 py-1 m-0" @click="!has_tease ? handleTeaseConfirmation() : null">
                                            <h5 class="mr-1">Tease</h5>
                                            <input type="checkbox" :id="`client_tease-${page.id}`" :true-value="1" :false-value="0" v-model="has_tease" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div> -->
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`page-${page.id}`" class="checkbox" v-if="!page.is_shared_with_contact">
                                                <input type="checkbox" :id="`page-${page.id}`" :value="page.id" v-model="selectedPage" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ page.page_views ? page.page_views : 0 }} Page Views</li>
                                        <li>{{ page.video_views ? page.video_views : 0 }} Video Views</li>
                                        <li>{{ page.button_clicks ? page.button_clicks : 0 }} Button Clicks</li>
                                        <li>{{ page.offer_clicks ? page.offer_clicks : 0 }} Offer Clicks</li>
                                    </ul>
                                </div>
                                <div v-tooltip="page.title">
                                    <router-link v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact" :to="{ name: 'PageEdit', params: { page: page.id }}" class="item_ttl">{{ page.title }}</router-link>
                                    <a v-else class="item_ttl">{{ page.title }}</a>
                                </div>
                                <div class="item_info">
                                    <div class="member pointer" @click="assignedMember = true; activePage = page;" v-tooltip="`Clients Assigned to this Page`">
                                        <img src="@/assets/images/member.svg">
                                        {{ page.user_count ? page.user_count : 0 }} Assigned
                                    </div>
                                    <ul>
                                        <li class="bg-transparent" v-tooltip="page.enabled == 1 ? 'Disable Page' : 'Enable Page'">
                                            <label :for="`page-toggle-${page.id}`" class="switch_option capsule_btn" v-if="!page.is_shared_with_contact">
                                                <input type="checkbox" :id="`page-toggle-${page.id}`" :checked="page.enabled == 1"  @change="handleEnableDisable($event, page)" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </li>
                                        <li @click="pageLink = true; activePage = page;" v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact"><span v-tooltip="`Live Page Link`"><i class="fas fa-link"></i></span></li>
                                        <li @click="moreOption(page.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  page.id}">
                                                <li v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact">
                                                    <router-link :to="{ name: 'PageEdit', params: { page: page.id }}">Edit Page</router-link>
                                                </li>
                                                <li @click="editThumbnail = true; activePage = page;" v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact">Edit Thumbnail Image</li>
                                                <li @click="renamePage = true; activePage = page;" v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact">Rename Page</li>
                                                <li @click="pageLink = true; activePage = page;" v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact">Page Link</li>
                                                <li @click="handleSharePage(page);" v-if="!page.is_shared_with_contact">Share Page</li>
                                                <li @click="movePage = true; activePage = page;" v-if="!page.is_shared_with_contact">Move to Folder</li>
                                                <li @click="shareLocation = true; activePage = page;" v-if="!page.is_shared_with_contact && (isSuperUser || user.is_company_location == 1)">Share to Location</li>
                                                <li v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact" @click="editDeliveryMessage = true; activePage = page;">Edit Delivery Message</li>
                                                <li v-if="companyUserCan('update', 'pages') && !page.is_shared_with_contact" @click="handleDuplicatePage([page.id])">Duplicate</li>
                                                <li v-if="companyUserCan('delete', 'pages') && !page.is_shared_with_contact" @click="handleDeletePage([page.id])" class="danger">Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_box" v-if="!pages.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any Pages yet. Click the play button to get started.</h4>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>{{ pages.from ? pages.from : 0 }} - {{ pages.to ? pages.to : 0 }} of {{ pages.total ? pages.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="pages.total && (!isArchived || folderParams.parent_id > 0)">
                    <pagination v-model="page" :pages="pages.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortPage = !sortPage" v-tooltip="`Sort Pages Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'pages')" @click="importPage = !importPage" v-tooltip="`Import a shared page`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    </ul>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedPage.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedPage.length ? `${selectedPage.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedPage.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('create', 'pages')" @click="handleDuplicatePage(selectedPage)">Duplicate</li>
                                    <li v-if="companyUserCan('delete', 'pages')" class="danger" @click="handleDeletePage(selectedPage)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="modeFilterDropdown2 = !modeFilterDropdown2" v-click-outside="closeModeFilterDropdown2">
                            {{ modeFilterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="modeFilterDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(mode, m) in modes" :key="m" :class="{'active' : mode.title == modeFilterTitle}" @click="sortingModeFilter(mode)">
                                        {{ mode.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/8b004daea84a404bb9ed8e917a832892?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>

        <div class="modal secondary prompt" v-if="teaseConformation">
            <div class="modal_container">
                <img src="@/assets/images/logo2.svg" alt="" class="logo_img">
                <div class="setting_wpr">
                    <div class="optin_type border-bottom">
                        <h4>Free <span>Your client will be redirected to the asset optin page or a URL of your choice.</span></h4>
                        <label for="free_teaser" class="switch_option capsule_btn">
                            <input type="radio" name="tease_type" id="free_teaser" :value="0" v-model="is_premium_teaser" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="optin_type">
                        <h4>Paid <span>Your client will be redirected to the asset checkout page or a URL of your choice.</span></h4>
                        <label for="paid_teaser" class="switch_option capsule_btn">
                            <input type="radio" name="tease_type" id="paid_teaser" :value="1" v-model="is_premium_teaser" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="form_grp pt-5">
                        <div class="group_item">
                            <label class="input_label">Please select a redirect URL <a class="option" @click="is_optin_url = !is_optin_url">{{is_optin_url ? 'Add from list' : '+ Add URL'}}</a></label>
                            <div class="field_wpr has_prefix" v-if="is_optin_url">
                                <input type="text" name="url" placeholder="https://thrivecoach.io/optin" v-model="optinURL" />
                                <span class="prefix">URL</span>
                            </div>
                            <div class="field_wpr" v-if="is_premium_teaser == 1 && !is_optin_url">
                                <Multiselect v-model="selectOptinItem" placeholder="Choose an opt-in" :options="['Premium Optin 1', 'Premium Optin 2', 'Premium Optin 3', 'Premium Optin 4']"></Multiselect>
                            </div>
                            <div class="field_wpr" v-if="is_premium_teaser == 0 && !is_optin_url">
                                <Multiselect v-model="selectOptinItem" placeholder="Choose an opt-in" :options="['Free Optin 1', 'Free Optin 2', 'Free Optin 3', 'Free Optin 4']"></Multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="action_wpr mt-5">
                    <button type="button" class="btn cancel_btn" @click="handleTeaseOff();">Cancel</button>
                    <button type="button" class="btn save_btn" @click="handleTeaseOn()">Tease</button>
                </div>
            </div>
        </div>

        <edit-thumbnail-asset v-model="editThumbnail" title="Page" :selectedAsset="activePage" :refreshAsset="refreshPage" />
        <sort-asset v-model="sortPage" title="Page" asset-type="pages" :update-sorting="sortingFilter" :folder-id="params.folder_id"/>
        <import-share-link v-model="importPage" title="Page" :refreshAsset="refreshPage" :has-saved-styling="true" />
        <rename-page v-model="renamePage" :page="activePage"/>
        <asset-link v-model="pageLink" title="Page" :selectedAsset="activePage" />
        <share-component v-model="sharePage" title="Page" :selectedAsset="activePage" />
        <move-asset v-model="movePage" title="Page" asset-type="pages" :selectedAsset="activePage" :refreshAsset="refreshPage" />
        <share-location v-model="shareLocation" title="Page" :selectedAsset="activePage" />
        <edit-delivery-message v-model="editDeliveryMessage" :activePage="activePage" />
        <assigned-member v-model="assignedMember" :activePage="activePage" />
        <page-disable v-model="pageDisable" :activePage="activePage" :toggleEnableDisable="resetPageEnableDisable" />
        <new-page v-model="newPage" :refresh-page="refreshPage"/>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const RenamePage = defineAsyncComponent(() => import('@/pages/page/components/RenamePage.vue'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const MoveAsset = defineAsyncComponent(() => import('@/components/MoveAsset'))
    const ShareLocation = defineAsyncComponent(() => import('@/components/ShareLocation'))
    const EditDeliveryMessage = defineAsyncComponent(() => import('@/pages/page/components/EditDeliveryMessage'))
    const AssignedMember = defineAsyncComponent(() => import('@/pages/page/components/AssignedMember'))
    const PageDisable = defineAsyncComponent(() => import('@/pages/page/components/PageDisable'))
    const NewPage = defineAsyncComponent(() => import('@/pages/page/components/NewPage'))

    import Pagination from '@hennge/vue3-pagination'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import Multiselect from '@vueform/multiselect';

    export default {
        name: 'Page Index',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                actionList: false,
                actionList2: false,
                modeFilterDropdown: false,
                modeFilterDropdown2: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                isTyping: false,
                filterTitle: 'Custom Arrangement',
                modeFilterTitle: 'All',
                filters: [
                    { title: 'Custom Arrangement', field: 'ordering', order: 'desc'},
                    { title: 'A-Z', field: 'title', order: 'asc'},
                    { title: 'Z-A', field: 'title', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Date Modified', field: 'updated_at', order: 'desc'},
                    { title: 'Page Views', field: 'page_views', order: 'desc'},
                    { title: 'Button Clicks', field: 'button_clicks', order: 'desc'},
                    { title: 'Video Views', field: 'video_views', order: 'desc'},
                    { title: 'Highest Submission Rate', field: 'submission_rate', order: 'desc'},
                    { title: 'Teased', field: 'is_teased', order: 'desc'},
                ],
                modes: [
                    { title: 'All', type: 'all'},
                    { title: 'Public', type: 'public'},
                    { title: 'Private', type: 'private'},
                ],
                search: '',
                perPageFilter: 6,
                isMoreOption: false,
                moreOptionDropdown: 0,
                selectedPage: [],
                editThumbnail: false,
                activePage: {},
                assetFolder: false,
                sortPage: false,
                importPage: false,
                assetFolderWatcher: 0,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                renamePage: false,
                pageLink: false,
                sharePage: false,
                movePage: false,
                shareLocation: false,
                editDeliveryMessage: false,
                assignedMember: false,
                pageDisable: false,
                newPage: false,
                page: 1,
                isLastBreadcrumb: false,
                showContent: false,
                searchField: false,
                mobile_filter: false,
                has_tease: 0,
                teaseConformation: 0,
                is_premium_teaser: 0,
                is_optin_url: false,
                optinURL: '',
                selectOptinItem: '',
            };
        },

        components: {
            // InnerSidebar,
            PageFilter,
            Pagination,
            Multiselect,
            EditThumbnailAsset,
            SortAsset,
            AssetFolder,
            ImportShareLink,
            RenamePage,
            AssetLink,
            ShareComponent,
            MoveAsset,
            ShareLocation,
            EditDeliveryMessage,
            AssignedMember,
            PageDisable,
            NewPage,
        },

        watch: {
            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getPages(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getPages(vm.params);
                        }
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.selectedPage = [];

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.getPages(vm.params);
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                pages: state => state.pageModule.pages,
                params: state => state.pageModule.params,
                pageLoader: state => state.pageModule.pageLoader,
                isArchived: state => state.folderModule.isArchived,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
                tagsGroup: state => state.tagModule.tagsGroup,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
                isSuperUser: 'authModule/IS_SUPER_USER',
            }),
        },

        mounted () {
            const vm = this;

            if (vm.isPreviousFolder) {
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                vm.folderParams.archived = vm.isArchived ? 1 : 0,
                vm.isLastBreadcrumb      = true;
                vm.assetFolder           = vm.isAssetFolder ? true : false;
            } else {
                vm.folderParams.archived = vm.params.archived ? 1 : 0;
            }

            vm.folderParams.parent_id = vm.params.folder_id ? vm.params.folder_id : 0;

            vm.setIsPreviousFolder(false);
            // vm.resetDashboardParams();

            if (vm.pages.total == 0 || !vm.pages.total) {
                vm.getPages(vm.params);
            }

            if (vm.params.search) {
                vm.search = vm.params.search;
            }

            if (vm.params.per_page) {
                vm.perPageFilter = vm.params.per_page;
            }

            if (vm.params.mode_title) {
                vm.modeFilterTitle = vm.params.mode_title;
            }

            if (vm.params.filter_title) {
                vm.filterTitle = vm.params.filter_title;
            }

            if (vm.params.page) {
                vm.page = vm.params.page;
            }

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            // vm.setIsArchivedStatus(false);

            document.getElementById('page_index').onclick = function() {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }
        },

        methods: {
            ...mapActions({
                getPages: 'pageModule/getPages',
                duplicatePage: 'pageModule/duplicatePage',
                deletePage: 'pageModule/deletePage',
                setEnableDisable: 'pageModule/setEnableDisable',
                getTags: 'tagModule/getTags',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setDashboardParams: 'pageModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'pageModule/RESET_DASHBOARD_PARAMS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList         = false;
                vm.bulkActionDropdown = false;
                vm.modeFilterDropdown = false;

                const filter = vm.$refs['page-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['page-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle     = filter.title;
                vm.setDashboardParams({ key: 'filter_title', value: filter.title });
                vm.setDashboardParams({ key: 'order_by', value: filter.field });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.selectedPage    = [];

                vm.getPages(vm.params);
            },

            sortingModeFilter (filter) {
                const vm = this;

                vm.modeFilterTitle = filter.title;
                vm.setDashboardParams({ key: 'mode_title', value: filter.title });
                vm.setDashboardParams({ key: 'mode', value: filter.type });

                vm.getPages(vm.params);
            },

            closeModeFilterDropdown () {
                const vm = this;

                vm.modeFilterDropdown = false;
            },
            closeModeFilterDropdown2 () {
                const vm = this;

                vm.modeFilterDropdown2 = false;
            },

            handlePagination (page) {
                const vm = this;

                vm.page = page;
                vm.setDashboardParams({ key: 'page', value: page });
                vm.selectedPage = [];

                vm.getPages(vm.params);
            },

            refreshPage () {
                const vm = this;

                vm.search = '';
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderParams.parent_id ? vm.folderParams.parent_id : null });
                vm.selectedPage   = [];

                vm.getPages(vm.params);
            },


            moreOption(id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;

                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });
                vm.refreshPage();
            },

            handleDuplicatePage (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate the selected ${id.length > 1 ? 'pages' : 'page'}`);

                    options.preConfirm = function () {
                                            return vm.duplicatePage({ id }).then((result) => {
                                                if (result) {
                                                    vm.search = '';
                                                    vm.selectedPage  = [];

                                                    vm.setDashboardParams({ key: 'search', value: '' });
                                                    vm.getPages(vm.params);
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one page');
                }
            },

            handleDeletePage (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${id.length > 1 ? 'these pages' : 'this page'}`, 'Delete');

                    options.preConfirm = function () {
                                            return vm.deletePage({ id }).then((result) => {
                                                if (result) {
                                                    vm.refreshPage();
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one page');
                }
            },

            handleEnableDisable (e, page) {
                const vm = this;

                if (e.target.checked) {
                    const option = Helper.swalConfirmOptions('Are you sure?', 'You want to enable this page!');

                    option.preConfirm = function () {
                                            const params = { id: page.id, enabled: 1, }

                                            vm.setEnableDisable(params).then((result) => {
                                                if (result) {
                                                    vm.refreshPage();
                                                }
                                            });
                                        };

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            e.target.checked = false;
                        }
                    });
                } else {
                    vm.pageDisable = true;
                    vm.activePage  = page;
                }
            },

            resetPageEnableDisable () {
                const vm = this;
                document.querySelector(`#page-toggle-${vm.activePage.id}`).checked = true;
            },

            handleSharePage (page) {
                const vm = this;

                if (page.share_count == 0) {
                    vm.sharePage = true;
                    vm.activePage = page;
                } else if (page.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);


                    Swal.fire(options);
                }
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            },

            handleTeaseConfirmation(){
                const vm = this;

                if(!vm.has_tease){
                    vm.teaseConformation = true;
                } else{
                    vm.teaseConformation = false;
                }

            },

            handleTeaseOn(){
                const vm = this;

                vm.has_tease = 1;
                vm.teaseConformation = false;
            },

            handleTeaseOff(){
                const vm = this;

                vm.has_tease = 0;
                vm.teaseConformation = false;
            }
        },

    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list>li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: -30px;
        top: 15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        transition: all 0.3s ease-in-out;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2c3e50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }
    .plybook_item .switch_list{
        position: absolute;
        right: -125px;
        bottom: 10px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transition: all 0.3s ease-in-out;
    }
    .plybook_item label.switch_option h5{
        font-size: 13px;
        color: #2c3e50;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    .plybook_item label.capsule_btn div{
        transform: scale(0.7);
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info>ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info>ul>li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics,
    .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    .plybook_item .item_img:hover .replace_img, .plybook_item .item_img:hover .switch_list {
        right: 15px;
    }

    @keyframes dropMove2 {
        from {
            margin-bottom: 10px;
            opacity: 0;
        }

        to {
            margin-bottom: 1px;
            opacity: 1;
        }
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13);
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-envelope {
        color: #f2a31d;
    }

    .action_list li i.fa-reply {
        color: #2f7eed;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .modal.redirect_modal .modal_container {
        height: 420px;
    }

    .modal.redirect_modal .section_ttl {
        font-size: 16px;
        line-height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
    }

    .modal.redirect_modal .section_ttl span {
        padding: 0 10px;
        background: #fff;
    }

    .modal.redirect_modal .section_ttl:after {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .redirect_list li {
        width: 33.333%;
        padding: 5px 8px;
    }

    .redirect_list li label h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        background: rgba(242, 163, 29, 0.05);
        border: 1px solid rgba(242, 163, 29, 0.3);
        color: #757575;
        filter: grayscale(1);
    }

    .redirect_list li label input[type=radio]:checked~h5 {
        filter: grayscale(0);
        color: #f2a31d;
    }

    .redirect_action {
        width: 100%;
        padding: 0 8px;
    }

    .modal.redirect_modal .action_wpr {
        margin-top: auto;
    }

    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    .modal.prompt .close_btn {
        position: absolute;
        right: -7px;
        left: auto;
        top: -7px;
        border: 1px solid #c7c7c7;
        background: rgba(50, 55, 59, 0.7);
        color: #fff;
        border-radius: 50%;
        font-size: 11px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        z-index: 1;
    }
    .modal.prompt .logo_img{
        max-height: 30px;
        width: auto;
        margin-bottom: 30px;
    }
    .modal.prompt .setting_wpr{
        max-width: 400px;
        width: 100%;
    }
    .modal.prompt .optin_type{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }
    .modal.prompt h4{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-right: 15px;
    }
    .modal.prompt h4 span{
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        display: block;
        padding-top: 3px;
    }
    .modal.prompt .action_wpr .btn.cancel_btn{
        background: #fff;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 767px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
